import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { 
  ActiveSCStep, 
  AvatarInitialsPipe, 
  FullNamePipe, 
  DateFormat, 
  AmountFormtedPipe, 
  SafeHtmlPipe, 
  SortByPipe, 
  CalculateDurationPipe
} from "./";
import { CheckAccessPipe } from './check-access.pipe';
@NgModule({
  declarations: [
    AvatarInitialsPipe, 
    FullNamePipe, 
    AmountFormtedPipe, 
    DateFormat, 
    SafeHtmlPipe, 
    SortByPipe,
    ActiveSCStep,
    CalculateDurationPipe,
    CheckAccessPipe
  ],
  imports: [CommonModule],
  exports: [
    AvatarInitialsPipe, 
    FullNamePipe,
    AmountFormtedPipe, 
    DateFormat, 
    SafeHtmlPipe, 
    SortByPipe,
    ActiveSCStep,
    CalculateDurationPipe,
    CheckAccessPipe
  ] 
})
export class MainPipe {}
