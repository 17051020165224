import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../services';
import { accessRoles } from '../constants/roles';

@Injectable()
export class RolesGuard implements CanActivate {

  constructor(private router: Router, private auth: AuthenticationService) {

  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    
    const user = JSON.parse(localStorage.getItem("current_user")).user

    const currentRoute = route.routeConfig.path || 'dashboard'
    
    const expectedRoles = accessRoles[currentRoute]

    const hasRole = expectedRoles.some(role => user.lissa_roles.includes(role));

    if (!hasRole) {
      if(!user.lissa_roles.includes("service-manager")){

        this.router.navigate(['/dashboard']);
      }
      else{
        this.router.navigate(['/clients-list']);
      }
      return false;
    }
    return true;
  }

}
